import { Controller } from "@hotwired/stimulus";
import { Tab } from 'bootstrap';

const ALERT_CLASSES = '.alert.alert-error';

export default class extends Controller {
  static targets = ['error', 'validateSelected']

  focusFirstError() {
    document.querySelectorAll(ALERT_CLASSES)[0].focus();
  }

  submitForm(event) {
    const isValid = this.validateForm();

    if (!isValid) {
      console.log('Form submission prevented!');
      event.preventDefault();
    }
  }

  focusTab(field) {
    const tabEl = field.closest('.tab-pane')
    if (tabEl) {
      const tabId = tabEl.getAttribute('id');
      const triggerEl = document.querySelector(`a[data-bs-target="#${tabId}"]`);
      Tab.getInstance(triggerEl).show();
      field.scrollIntoView();
    }
    field.focus();
  }

  generateErrorDiv(field, errorMessage) {
    const parent = field.parentElement;
    const errorElement = parent.querySelector(ALERT_CLASSES);
    if (errorElement) {
      errorElement.scrollIntoView();
      return;
    };
    const errorDiv = document.createElement("div");
    const errorContent = document.createTextNode(errorMessage);
    errorDiv.classList.add('alert');
    errorDiv.classList.add('alert-error');
    errorDiv.appendChild(errorContent);
    parent.appendChild(errorDiv);
  }

  validateForm() {
    let isValid = true;

    // Tell the browser to find any required fields
    let requiredFieldSelectors = 'textarea:required, input:required, trix-editor[required]';
    let requiredFields = this.element.querySelectorAll(requiredFieldSelectors);

    requiredFields.forEach((field) => {
      // For each required field, check to see if the value is empty
      // if so, we focus the field and set our value to false
      if (!field.disabled && !field.value.trim()) {
        this.focusTab(field);

        isValid = false;
        this.generateErrorDiv(field, "Cannot be blank!");
        return false;
      }
    });

    // If we already know we're invalid, just return false
    if (!isValid) {
      console.log('Some of the required fields is not valid')
      return false;
    }

    // Search for any browser invalidated input fields and focus them
    let invalidFields = this.element.querySelectorAll('input:invalid');

    invalidFields.forEach((field) => {
      if (!field.disabled) {
        field.focus();

        isValid = false;
      }
    });
    if (!isValid) {
      console.log('Field is set as invalid from the browser');
      return isValid;
    }

    isValid = this.validateSelectedTargets.every(group => {
      isValid = Array.from(group.querySelectorAll('input[type="checkbox"]')).some((checkbox) => {
        return checkbox.checked;
      })

      if (!isValid) {
        console.log('Selected targets not set... form invalid')
        const errorElement = group.querySelector(ALERT_CLASSES);
        if (errorElement) {
          group.scrollIntoView();
          return
        };
        const newDiv = document.createElement("div");
        const gameProperty = group.dataset.gameProperty;
        const newContent = document.createTextNode(`Select at least one ${gameProperty}`);
        newDiv.classList.add('alert');
        newDiv.classList.add('alert-error');
        newDiv.appendChild(newContent);
        group.appendChild(newDiv);
        this.focusTab(group);
      }
    })

    return isValid;
  }
}
