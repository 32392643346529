import Clipboard from 'stimulus-clipboard'

export default class extends Clipboard {
  static targets = ['button', 'source', 'template']

  connect() {
    super.connect();
  }

  copy() {
    event.preventDefault();

    const text = this.sourceTarget.innerText || this.sourceTarget.value;

    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(text).then(() => this.copied());
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = text;

      // Move textarea out of the viewport so it's not visible
      textArea.style.position = "absolute";
      textArea.style.left = "-999999px";

      document.body.prepend(textArea);
      textArea.select();

      try {
        document.execCommand('copy');
        this.copied();
      } catch (error) {
        console.error(error);
      } finally {
        textArea.remove();
      }
    }
  }

  // Function to override when no input is copied.
  copied() {
    if (!this.hasButtonTarget) return

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.buttonTarget.innerHTML = this.templateTarget.innerHTML;

    this.timeout = setTimeout(() => {
      this.buttonTarget.innerHTML = this.originalContent;
    }, this.successDurationValue)
  }
}
